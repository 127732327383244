<template>
    <div class="wrapper">
        <!-- 产销情况 -->
        <table class="custom-table">
            <tbody>
                <tr>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:1rem;">
                        产品分类
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:.6rem;">
                        序号
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:1rem;">
                        产品子类
                    </td>
                    <td class="custom-th custom-th-bg" colspan="4">
                        销量(万方)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2">
                        售价(元/方)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2">
                        成本(元/方)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:1rem;">
                        毛利率(%)
                    </td>
                </tr>
                <tr>
                    <td class="custom-th custom-th-bg">
                        本月
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1rem;">
                        本年累计
                    </td>
                    <td class="custom-th custom-th-bg">
                        本年计划
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1rem;">
                        年度计划完成率(%)
                    </td>
                </tr>
                <template v-for="(item, index) in data.concretes">
                    <tr :key="item.rsc_id">
                        <td class="custom-th-even" :rowspan="data.concretes.length+1" v-if="index===0">
                            混凝土
                        </td>
                        <td class="border6">
                            {{ item.sort }}
                        </td>
                        <td class="border6">
                            {{ item.name }}
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.sales"
                                :disabled="type=='approval'"
                                @blur="handleSales('sales', item, item.sales)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.sales)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.sales_year)<0}" class="border6">
                            {{ item.sales_year }}
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.plan_year"
                                :disabled="type=='approval'"
                                @blur="handleSales('plan_year', item, item.plan_year)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.plan_year)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.sales_year_finish)<0}" class="border6">
                            {{ item.sales_year_finish }}
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.price"
                                :disabled="type=='approval'"
                                @blur="handleSales('price', item, item.price)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.price)<0}"
                            ></el-input>
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.cost"
                                :disabled="type=='approval'"
                                @blur="handleSales('cost', item, item.cost)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.cost)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.margin_rate)<0}" class="border6">
                            {{ item.margin_rate }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="2">
                        总体
                    </td>
                    <td :class="{'red': parseFloat(data.total.concrete_sales)<0}">
                        {{ data.total.concrete_sales }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.concrete_sales_year)<0}">
                        {{ data.total.concrete_sales_year }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.concrete_plan_year)<0}">
                        {{ data.total.concrete_plan_year }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.concrete_sales_year_finish)<0}">
                        {{ data.total.concrete_sales_year_finish }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.concrete_price)<0}">
                        {{ data.total.concrete_price }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.concrete_cost)<0}">
                        {{ data.total.concrete_cost }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.concrete_margin_rate)<0}">
                        {{ data.total.concrete_margin_rate }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:1rem;">
                        产品分类
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:.6rem;">
                        序号
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:1rem;">
                        产品子类
                    </td>
                    <td class="custom-th custom-th-bg" colspan="4">
                        销量(万吨)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2">
                        售价(元/吨)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2">
                        成本(元/吨)
                    </td>
                    <td class="custom-th custom-th-bg" rowspan="2" style="width:1rem;">
                        毛利率(%)
                    </td>
                </tr>
                <tr>
                    <td class="custom-th custom-th-bg">
                        本月
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1rem;">
                        本年累计
                    </td>
                    <td class="custom-th custom-th-bg">
                        本年计划
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1rem;">
                        年度计划完成率(%)
                    </td>
                </tr>
                <template v-for="(item, index) in data.mortars">
                    <tr :key="item.rsm_id">
                        <td :rowspan="data.mortars.length+1" v-if="index===0" class="custom-th-odd">
                            砂浆
                        </td>
                        <td class="border6">
                            {{ item.sort }}
                        </td>
                        <td class="border6">
                            {{ item.name }}
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.sales"
                                :disabled="type=='approval'"
                                @blur="handleMortars('sales', item, item.sales)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.sales)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.sales_year)<0}" class="border6">
                            {{ item.sales_year }}
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.plan_year"
                                :disabled="type=='approval'"
                                @blur="handleMortars('plan_year', item, item.plan_year)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.plan_year)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.sales_year_finish)<0}" class="border6">
                            {{ item.sales_year_finish }}
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.price"
                                :disabled="type=='approval'"
                                @blur="handleMortars('price', item, item.price)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.price)<0}"
                            ></el-input>
                        </td>
                        <td class="border6">
                            <el-input
                                v-model="item.cost"
                                :disabled="type=='approval'"
                                @blur="handleMortars('cost', item, item.cost)"
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :class="{'red': parseFloat(item.cost)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(item.margin_rate)<0}" class="border6">
                            {{ item.margin_rate }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="2">
                        总体
                    </td>
                    <td :class="{'red': parseFloat(data.total.mortar_sales)<0}">
                        {{ data.total.mortar_sales }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.mortar_sales_year)<0}">
                        {{ data.total.mortar_sales_year }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.mortar_plan_year)<0}">
                        {{ data.total.mortar_plan_year }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.mortar_sales_year_finish)<0}">
                        {{ data.total.mortar_sales_year_finish }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.mortar_price)<0}">
                        {{ data.total.mortar_price }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.mortar_cost)<0}">
                        {{ data.total.mortar_cost }}
                    </td>
                    <td :class="{'red': parseFloat(data.total.mortar_margin_rate)<0}">
                        {{ data.total.mortar_margin_rate }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
        typeList: {
            type: [Array],
        },
    },
    data() {
        return {
            data: {
                total: {},
                concretes: {},
                mortars: {},
            },
            tableHeight: 0,
            oldData: {},
            preMonRes: null,
        };
    },
    watch: {
    },
    computed: {},
    methods: {
        // 获取指定年月的报表实例信息
        getInstance() {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取上月产销概况
        getPreMonSales(instanceRes) {
            return this.$axios.get('/interfaceApi/report/sales/' + instanceRes.report_id);
        },
        // 获取产销概况
        getSales() {
            this.$axios
                .get('/interfaceApi/report/sales/' + this.reportMonth.report_id)
                .then(async res => {
                    if (res) {
                        this.data = res;
                        this.oldData = JSON.parse(JSON.stringify(res));
                        if (this.type !== 'approval') {
                            if (this.typeList[0].state === 0) {
                                if (this.reportMonth.report_month > 1) {
                                    const instanceRes = await this.getInstance();
                                    // eslint-disable-next-line max-depth
                                    if (instanceRes) {
                                    // eslint-disable-next-line max-depth
                                        if (instanceRes.report_state === 3) {
                                            const preMonRes = await this.getPreMonSales(instanceRes);
                                            // eslint-disable-next-line max-depth
                                            if (preMonRes) {
                                                res.total.concrete_plan_year = preMonRes.total.concrete_plan_year;
                                                res.total.mortar_plan_year = preMonRes.total.mortar_plan_year;
                                                res.total.concrete_sales_year = preMonRes.total.concrete_sales_year;
                                                res.total.mortar_sales_year = preMonRes.total.mortar_sales_year;
                                                res.concretes.map((item, index) => {
                                                    item.plan_year = preMonRes.concretes[index].plan_year;
                                                    item.sales_year = preMonRes.concretes[index].sales_year;
                                                });
                                                res.mortars.map((item, index) => {
                                                    item.plan_year = preMonRes.mortars[index].plan_year;
                                                    item.sales_year = preMonRes.mortars[index].sales_year;
                                                });
                                            }
                                        } else {
                                            this.$message.warning('上月产销未填报审核通过，请先填写，以免影响本年累计计算！');
                                        }
                                    }
                                }
                            }
                            if (this.reportMonth.report_month > 1) {
                                const instanceRes = await this.getInstance();
                                if (instanceRes) {
                                    // eslint-disable-next-line max-depth
                                    if (instanceRes.report_state === 3) {
                                        const preMonRes = await this.getPreMonSales(instanceRes);
                                        // eslint-disable-next-line max-depth
                                        if (preMonRes) {
                                            this.preMonRes = preMonRes;
                                            res.concretes.map((item, index) => {
                                                item.sales_year_pre = this.preMonRes.concretes[index].sales_year;
                                            });
                                            res.mortars.map((item, index) => {
                                                item.sales_year_pre = this.preMonRes.mortars[index].sales_year;
                                            });
                                            this.oldData = JSON.parse(JSON.stringify(res));
                                        }

                                    }
                                }
                            }
                            this.oldData = JSON.parse(JSON.stringify(res));
                        }

                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存产销概况
        save() {
            const data = { };
            const totalB = util.Compare(this.oldData.total, this.data.total);
            if (!totalB) {
                data.total = this.data.total;
            }
            const concretes = [];
            const editData = this.data;
            this.oldData.concretes.map((item, index) => {
                const boo = util.Compare(item, editData.concretes[index]);
                if (!boo) {
                    concretes.push(editData.concretes[index]);
                }
            });
            if (concretes.length > 0) {
                data.concretes = concretes;
            }
            const mortars = [];
            this.oldData.mortars.map((item, index) => {
                const boo = util.Compare(item, editData.mortars[index]);
                if (!boo) {
                    mortars.push(editData.mortars[index]);
                }
            });
            if (mortars.length > 0) {
                data.mortars = mortars;
            }
            if (Object.keys(data).length < 1) {
                this.$message.warning('暂无修改内容！');
                this.$emit('save-end', 'sales', 'validateFailed');
                return;
            }
            const json = this.filterData(data);
            if (!json.isExistMinus) {
                this.$axios
                    .post('/interfaceApi/report/sales/save', json.parseData)
                    .then(async res => {
                        if (res) {
                            // 保存完重新赋值
                            if (res.hasOwnProperty('total') && res.total) {
                                this.data.total = res.total;
                            }
                            if (res.hasOwnProperty('concretes') && res.concretes) {
                                res.concretes.map(item => {
                                    this.data.concretes = this.data.concretes.map((v, index) => {
                                        if (v.name === item.name) {
                                            v = item;
                                            if (this.preMonRes) {
                                                v.sales_year_pre = this.preMonRes.concretes[index].sales_year;
                                            }
                                        }
                                        return v;
                                    });
                                });
                            }
                            if (res.hasOwnProperty('mortars') && res.mortars) {
                                res.mortars.map(item => {
                                    this.data.mortars = this.data.mortars.map((v, index) => {
                                        if (v.name === item.name) {
                                            v = item;
                                            if (this.preMonRes) {
                                                v.sales_year_pre = this.preMonRes.mortars[index].sales_year;
                                            }
                                        }
                                        return v;
                                    });
                                });
                            }
                            this.$message.success('保存成功');
                            this.$emit('save-end', 'sales', 'success');
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                        this.$emit('save-end', 'sales', 'error');
                    });
            } else {
                this.$message.warning('存在负数，请重新填写！');
                this.$emit('save-end', 'sales', 'validateFailed');
            }
        },
        // 过滤数据 null的转换成0,并判断是否存在负数
        filterData(data) {
            const json = { parseData: data, isExistMinus: false };
            let totalFalse = false;
            if (data.total) {
                for (const key in data.total) {
                    let notParse = false;
                    if (key === 'rs_id' || key === 'report_id' || key === 'mortar_sales_year'
                    || key === 'mortar_sales_year_finish' || key === 'mortar_margin_rate' || key === 'concrete_margin_rate') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!data.total[key]) {
                            data.total[key] = 0;
                        } else {
                            data.total[key] = parseFloat(data.total[key]);
                        }
                        if (data.total[key] < 0) {
                            totalFalse = true;
                        }
                    }
                }
            }
            let concreteFalse = false;
            if (data.concretes) {
                data.concretes = data.concretes.map(item => {
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rsc_id' || key === 'report_id' || key === 'name' || key === 'sort' || key === 'sort_num'
                        || key === 'sales_year' || key === 'sales_year_finish' || key === 'margin_rate') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                concreteFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            let mortarsFalse = false;
            if (data.mortars) {
                data.mortars = data.mortars.map(item => {
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rsm_id' || key === 'report_id' || key === 'name' || key === 'sort' || key === 'sort_num'
                        || key === 'sales_year' || key === 'sales_year_finish' || key === 'margin_rate') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                mortarsFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            json.parseData = data;
            if (totalFalse || concreteFalse || mortarsFalse) {
                json.isExistMinus = true;
            }
            return json;
        },
        // 混凝土-本年累计销量
        getTotalSalesYear(name, type) {
            return this.$axios.get('/interfaceApi/report/sales/totalsalesyear/' + this.reportMonth.report_id + '/' + type + '/' + name);
        },
        // 计算-混凝土-本月合计
        async handleSales(type, data, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            let sales = 0;
            let sumPrice = 0;
            let sumCost = 0;
            let planYear = 0;
            this.data.concretes.map(item => {
                // 销量相关
                if (item.sales ) {
                    // 本月累计销量
                    sales = util.toFixed6(parseFloat(sales) + parseFloat(item.sales));
                }
                // 售价相关
                if (item.sales && item.price ) {
                    sumPrice = util.toFixed6(parseFloat(sumPrice) + parseFloat(item.sales) * parseFloat(item.price));
                }
                // 成本相关
                if (item.sales && item.cost ) {
                    sumCost = util.toFixed6(parseFloat(sumCost) + parseFloat(item.sales) * parseFloat(item.cost));
                }
                // 本年计划完成
                if (item.plan_year ) {
                    planYear = util.toFixed6(parseFloat(planYear) + parseFloat(item.plan_year));
                }
                return item;
            });
            this.data.concretes.map(item => {
                if (data.name === item.name) {
                    // 单条 本年计划完成率
                    if (parseFloat(item.plan_year) && parseFloat(item.sales_year) ) {
                        item.sales_year_finish = util.toFixed6(parseFloat(item.sales_year) / parseFloat(item.plan_year) * 100);
                    } else {
                        item.sales_year_finish = 0;
                    }
                    // 单条 毛利率
                    if (parseFloat(item.price) && parseFloat(item.cost)) {
                        item.margin_rate = util.toFixed6((parseFloat(item.price) - parseFloat(item.cost)) / parseFloat(item.price) * 100);
                    } else {
                        item.margin_rate = 0;
                    }
                }
                return item;
            });
            // 本年完成率
            this.data.total.concrete_plan_year = planYear;
            if (parseFloat(this.data.total.concrete_sales_year) && parseFloat(this.data.total.concrete_plan_year)) {
                this.data.total.concrete_sales_year_finish
                 = util.toFixed6(parseFloat(this.data.total.concrete_sales_year) / parseFloat(this.data.total.concrete_plan_year) * 100);
            } else {
                this.data.total.concrete_sales_year_finish = 0;
            }
            // 本年累计销量
            if (type === 'sales') {
                let concrete_sales_year = 0;
                if (!data.sales) {
                    data.sales = 0;
                }
                if (!data.sales_year_pre) {
                    data.sales_year_pre = 0;
                }
                data.sales_year = util.toFixed6(parseFloat(data.sales_year_pre) + parseFloat(data.sales));
                this.data.concretes.map(item => {
                    if (data.name === item.name) {
                        item = data;
                        // 单条 本年计划完成率
                        if (parseFloat(item.plan_year) && parseFloat(item.sales_year)) {
                            item.sales_year_finish = util.toFixed6(parseFloat(item.sales_year) / parseFloat(item.plan_year) * 100);
                        } else {
                            item.sales_year_finish = 0;
                        }
                    }
                    if (item.sales_year) {
                        if (item.sales_year) {
                            concrete_sales_year = util.toFixed6(parseFloat(concrete_sales_year) + parseFloat(item.sales_year));
                        }

                    }
                    return item;
                });
                // 本年累计
                this.data.total.concrete_sales_year = util.toFixed6(concrete_sales_year);
            }


            /* 计算总计 */
            // 销量相关
            this.data.total.concrete_sales = util.toFixed6(sales);
            // 售价相关
            if (parseFloat(sumPrice) && parseFloat(this.data.total.concrete_sales)) {
                this.data.total.concrete_price = util.toFixed6(parseFloat(sumPrice) / parseFloat(this.data.total.concrete_sales));
            } else {
                this.data.total.concrete_price = 0;
            }
            // 成本相关
            if (parseFloat(sumCost) && parseFloat(this.data.total.concrete_sales)) {
                this.data.total.concrete_cost = util.toFixed6(parseFloat(sumCost) / parseFloat(this.data.total.concrete_sales));
            } else {
                this.data.total.concrete_cost = 0;
            }
            // 毛利率
            if (parseFloat(this.data.total.concrete_price)) {
                this.data.total.concrete_margin_rate
                = util.toFixed6((parseFloat(this.data.total.concrete_price) - parseFloat(this.data.total.concrete_cost))
                / parseFloat(this.data.total.concrete_price) * 100);
            } else {
                this.data.total.concrete_margin_rate = 0;
            }
        },
        // 计算-砂浆
        async handleMortars(type, data, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数');
                    return;
                }
            }
            let sales = 0;
            let sumPrice = 0;
            let sumCost = 0;
            let planYear = 0;
            this.data.mortars.map(item => {
                // 销量相关
                if (item.sales ) {
                    // 本月累计销量
                    sales = util.toFixed6(parseFloat(sales) + parseFloat(item.sales));
                }
                // 售价相关
                if (item.sales && item.price ) {
                    sumPrice = util.toFixed6(parseFloat(sumPrice) + parseFloat(item.sales) * parseFloat(item.price));
                }
                // 成本相关
                if (item.sales && item.cost ) {
                    sumCost = util.toFixed6(parseFloat(sumCost) + parseFloat(item.sales) * parseFloat(item.cost));
                }
                // 本年计划完成
                if (item.plan_year ) {
                    planYear = util.toFixed6(parseFloat(planYear) + parseFloat(item.plan_year));
                }
                return item;
            });
            this.data.mortars.map(item => {
                if (data.name === item.name) {
                    // 单条 本年计划完成率
                    if (parseFloat(item.plan_year) && parseFloat(item.sales_year)) {
                        item.sales_year_finish = util.toFixed6(parseFloat(item.sales_year) / parseFloat(item.plan_year) * 100);
                    } else {
                        item.sales_year_finish = 0;
                    }
                    // 单条 毛利率
                    if (parseFloat(item.price) && parseFloat(item.cost)) {
                        item.margin_rate = util.toFixed6((parseFloat(item.price) - parseFloat(item.cost)) / parseFloat(item.price) * 100);
                    } else {
                        item.margin_rate = 0;
                    }
                }
                return item;
            });
            // 本年完成率
            this.data.total.mortar_plan_year = planYear;
            if (parseFloat(this.data.total.mortar_sales_year) && parseFloat(this.data.total.mortar_plan_year)) {
                this.data.total.mortar_sales_year_finish
                 = util.toFixed6(parseFloat(this.data.total.mortar_sales_year) / parseFloat(this.data.total.mortar_plan_year) * 100);
            } else {
                this.data.total.mortar_sales_year_finish = 0;
            }
            // 本年累计销量
            if (type === 'sales') {
                // const res = await this.getTotalSalesYear(data.name, 2);
                // let mortar_sales_year = 0;
                // if (res || res === 0) {

                // }
                let mortar_sales_year = 0;
                if (!data.sales) {
                    data.sales = 0;
                }
                if (!data.sales_year_pre) {
                    data.sales_year_pre = 0;
                }
                data.sales_year = util.toFixed6(parseFloat(data.sales_year_pre) + parseFloat(data.sales));
                this.data.mortars.map(item => {
                    if (data.name === item.name) {
                        item = data;
                    }
                    if (item.sales_year ) {
                        mortar_sales_year = util.toFixed6(parseFloat(mortar_sales_year) + parseFloat(item.sales_year));
                    }
                    return item;
                });
                // 本年累计
                this.data.total.mortar_sales_year = mortar_sales_year;
            }


            /* 计算总计 */
            // 销量相关
            this.data.total.mortar_sales = util.toFixed6(sales);
            // 售价相关
            if (parseFloat(sumPrice) && parseFloat(this.data.total.mortar_sales)) {
                this.data.total.mortar_price = util.toFixed6(parseFloat(sumPrice) / parseFloat(this.data.total.mortar_sales));
            } else {
                this.data.total.mortar_price = 0;
            }
            // 成本相关
            if (parseFloat(sumCost) && parseFloat(this.data.total.mortar_sales)) {
                this.data.total.mortar_cost = util.toFixed6(parseFloat(sumCost) / parseFloat(this.data.total.mortar_sales));
            } else {
                this.data.total.mortar_cost = 0;
            }
            // 毛利率
            if (parseFloat(this.data.total.mortar_price)) {
                this.data.total.mortar_margin_rate
                = util.toFixed6((parseFloat(this.data.total.mortar_price) - parseFloat(this.data.total.mortar_cost))
                / parseFloat(this.data.total.mortar_price) * 100);
            } else {
                this.data.total.mortar_margin_rate = 0;
            }
        },
    },
    created() {
        setTimeout(() => {
            this.getSales();
        }, 0);
    },
};
</script>
<style>
.wrapper{
    height: 100%;
}
.el-table .el-input__inner{
    width:.52rem;
    height:.32rem;
    line-height:.32rem;
}
.el-table .cell{
    text-align: center;
}
.el-table td, .el-table th{
    padding: .05rem 0;
}
.el-table--border th.gutter:last-of-type{
    border-bottom: none!important;
}
.el-table th>.cell{
    color: #022782;
}
</style>